import { Modal as BootstrapModal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const WithdrawReportDetailsModal = ({ hide, show, data }) => {

    console.log("🚀 ~ WithdrawReportDetailsModal ~ data:", data)

    return (
        <BootstrapModal
            show={show}
            onHide={hide}
            centered
            className="dark:bg-gray-800 "
        >
            <BootstrapModal.Header
                closeButton
                className="text-black dark:border-gray-700 "
            >
                <BootstrapModal.Title>Location Details</BootstrapModal.Title>
            </BootstrapModal.Header>
            <BootstrapModal.Body className="dark:text-white ">
                {data ? (
                    <div className="flex flex-col gap-2">
                        <p className="text-black">
                            <strong>Transection ID:</strong> {data?._id}   <button
                                onClick={() => {
                                    navigator.clipboard.writeText(data?._id);
                                    toast.success("Trasaction id copied to clipboard!");
                                }}
                                className="ml-2"
                                style={{ color: "lightblue" }}
                            >
                                <FontAwesomeIcon icon={faCopy} />
                            </button>
                        </p>
                        <p className="text-black">
                            <strong>Transfer Address:</strong>{" "}
                            {data?.Transfer &&
                                `${data.Transfer.slice(0, 16)}...${data.Transfer.slice(-6)}`} {/* Slices the address */}
                            <button
                                onClick={() => {
                                    navigator.clipboard.writeText(data?.Transfer);
                                    toast.success("Transfer Address copied to clipboard!");
                                }}
                                className="ml-2"
                                style={{ color: "lightblue" }}
                            >
                                <FontAwesomeIcon icon={faCopy} />
                            </button>
                        </p>

                        <p className="text-black">
                            <strong>Beneficiary Address:</strong>{" "}
                            {data?.Beneficiary &&
                                `${data.Beneficiary.slice(0, 16)}...${data.Beneficiary.slice(-6)}`} {/* Slices the address */}
                            <button
                                onClick={() => {
                                    navigator.clipboard.writeText(data?.Beneficiary);
                                    toast.success("Beneficiary copied to clipboard!");
                                }}
                                className="ml-2"
                                style={{ color: "lightblue" }}
                            >
                                <FontAwesomeIcon icon={faCopy} />
                            </button>
                        </p>



                    </div>
                ) : (
                    <h3 className="text-black">No data found!</h3>
                )}
            </BootstrapModal.Body>
            <BootstrapModal.Footer className="dark:bg-gray-800 dark:border-t dark:border-gray-700">
                <Button variant="secondary" className="text-black" onClick={hide}>
                    Close
                </Button>
            </BootstrapModal.Footer>
        </BootstrapModal>
    );
};

export default WithdrawReportDetailsModal;
